import { bible } from '../../bible.js';
import { normal } from '../../normal.js';
import { global } from '../../global.js';
import './footer.js';
import './header.js';
import { FASTElement, html, customElement } from '@microsoft/fast-element';
const template = html<HeaderFooter>`
  <app-header></app-header>
  <main>
    <section>
    <slot></slot>
    </section>
  </main>
  <app-footer></app-footer>
`;
@customElement({
  name: 'header-footer',
  template,
  styles: [ bible, normal, global ]
})
export class HeaderFooter extends FASTElement {}

