import { css } from "@microsoft/fast-element";

export const normal = css`
/* general styles */
main, section, header, nav, article,
footer,  option, pre, code,
blockquote, 
div, span, section,
legend, fieldset, 
menu, ol, ul, li,
h1, h2, h3, h4, h5, p, a,
textarea, input,
dialog, form, button,
textarea, input
{
    margin: 0;
	  padding: 0;
    font-family: inherit;

    background-color: transparent;
    border: none;
    outline: none;
}

input[type="range"] {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

ul, ol, menu {
  list-style: none;
  text-align: center;
}

/* viewports */
main {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 50vh;
}
section {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  width: 85%;

  padding: 1%;
  margin: 1% 0;
  background: transparent;
  border: none;
}


  a {
    width: 100%;
    font-size: clamp(16px, 2.5vw, 18px);
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
  a[title="nav"] {
    font-size: clamp(16px, 2.5vw, 18px);
  }
  button {
  cursor: pointer;
  width: 100%;
  margin: .5% 0;
  padding: 1%;
  }

  textarea, input {
  font-size: clamp(12px, 1vw, 16px);
  width: 100%;
  padding: 1%;
  margin: .5% 0;
}
textarea {
  resize: none;
}
textarea:focus, input:focus {
outline: none;
}
;`