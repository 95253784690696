import { bible } from '../../bible.js';
import { global } from '../../global.js';
import { normal } from '../../normal.js';
import { type ProjectItem, kProjectData } from '../data/index.js';
import '../components/app-page.js';
import { FASTElement, html, css, customElement } from '@microsoft/fast-element';
const styles = [
  bible,
  normal,
  global,
  css`
    .plein {
      justify-content: center;
      align-items: center;
      width: 100%;

      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .logoLink2 {
      display: none;
    }
    .logoLink {
      justify-content: center;
      align-items: center;
      width: 25%;
      margin: 2.5%;
    }
    .logoLink img {
      width: clamp(225px, 15vw, 275px);
    }

   

    .prjxCard {
      border: 2px solid var(--tert_2);
      width: 25%;
    }
    .prjxCard legend {
      border: 2px solid var(--tert_2);
    }
    .prjxGrid {
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .prjxGrid li {
      width: 40%;
      justify-content: center;
    }
    .prjxGrid img {
      width: clamp(150px, 10vw, 175px);
    }
    .prjxGrid button {
      width: 55%;
    }
    fieldset {
      flex-flow: column wrap;
      width: 35%;
    }
    fieldset div {
      display: flex;
      flex-flow: column wrap;

      width: 75%;
      padding: 2.5%;
      border-radius: 16px;
    }

    .blogCard {
      border: 2px solid var(--tert_2);
      width: 25%;
      height: 250px;
      max-height: 250px;
      overflow-y: scroll; 
    }
    .blogCard h3 {
      width: 100%;
    }
    .blogCard ul {
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .blogCard li {
      flex-flow: column nowrap;
      padding: 2.5%;
      border: 2px solid var(--tert_2);
      border-radius: 16px;
      width: 85%;
      margin: 1.5%;
    }
    .blogCard a {
      flex-flow: column wrap;
      text-align: start;
      width: 100%;
    }
    .blogCard legend {
      width: 65%;
      border: 2px solid var(--tert_2);
    }

    @media screen and (max-width: 768px) {
      .plein {
        flex-flow: column wrap;
      }
      .prjxCard {
         width: 55%;
         margin: 1% 0;
       }
       .blogCard {
        width: 50%;
        margin: 1% 0;
      }
       .logoLink {
        display: none;
       }

       .logoLink2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35%;
        margin: 1%;
      }
      .logoLink2 img {
        width: clamp(225px, 15vw, 275px);
      }
    }
  `,
];
const template = html<AppLinktree>`
  <app-page>   

    <span class="plein">
    <a class="logoLink2" href="/amaka">
        <img src="/assets/brand-kit/cl_AMAKA2.svg" />
      </a>

    <fieldset class="prjxCard">
          <legend><a href="/portfolio"><h3>current projects</h3></a></legend>
          <ul class="prjxGrid">
            ${kProjectData
              .map((item: ProjectItem) => {
                return `
          <li> 
            <button> 
            <img src="${item.logo}" />  
            </button>
          </li>
        `;})
        .join('')}
          </ul>

            <div>
              <h4>project title</h4>
              <p>lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
          </fieldset>

          <a class="logoLink" href="/amaka">
        <img src="/assets/brand-kit/cl_AMAKA2.svg" />
      </a>

      <fieldset class="blogCard">
          <legend><a href="/musings"><h3>amaka's musings</h3></a></legend>
          <ul>
          
          <li> 
            <a href="#"> 
            <h5>post title</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </a>
          </li>
          <li> 
            <a href="#"> 
            <h5>post title</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </a>
          </li>
          <li> 
            <a href="#"> 
            <h5>post title</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </a>
          </li>
          <li> 
            <a href="#"> 
            <h5>post title</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </a>
          </li>
          <li> 
            <a href="#"> 
            <h5>post title</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </a>
          </li>
          <li> 
            <a href="#"> 
            <h5>post title</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </a>
          </li>
              </ul>
          </fieldset>   
      </span>

  </app-page>
`;
@customElement({ name: 'app-linktree', template, styles })
export class AppLinktree extends FASTElement {}
