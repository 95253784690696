import { FASTElement, html, css, customElement } from '@microsoft/fast-element';

import { bible } from '../../bible.js';
import { normal } from '../../normal.js';
import { global } from '../../global.js';
import { kLandingItems } from '../data/index.js';
import '../components/header-footer.js';

const kAttrMenu = html`
  <span class="vert">
    <ul class="attrMenu">
      ${kLandingItems.attributes
        .map((item) => {
          return `
      <li class="medium">
        <img id="icon" src="${item.img}" />
        <h3>${item.label}</h3>
      </li>
    `;
        })
        .join('')}
    </ul>
    <div class="nameDiv">
      <h1>Amaka Ihejirika</h1>
      <h2>she/her</h2>
    </div>
  </span>
`;

const kPrjxMenu = html`
   <fieldset class="socialsCard">
          <legend><button><h3>instagram</h3></button>
          <button><h3>goodreads</h3></button>
          <button><h3>tumblr</h3></button></legend>
          <ul class="prjxGrid">
          <li> 
            <a href="#"> 
            <h5>post title</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </a>
          </li>
          </ul>

            <div>
              <h4>project title</h4>
              <p>lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
          </fieldset>
`;
const kSkillMenu = html`
  <fieldset class="skillMenu">
    <legend><h3>workflow</h3></legend>
    <ul>
      ${kLandingItems.skills
        .map((item) => {
          return `
      <li class="tiny">
      <a target="_blank" href="${item.href}">
        <img id="icon" src="${item.img}" />
      </a>
    </li>
      `;
        })
        .join('')}
    </ul>
  </fieldset>
`;
const template = html`
  <header-footer>
   ${kAttrMenu} ${kSkillMenu} ${kPrjxMenu}
    </header-footer>
`;
const styles = [
  bible,
  global,
  normal,
  css`

  .socialsCard {
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--tert_2);
    width: 75%;
  }
  .socialsCard legend {
    flex-flow: row wrap;
    justify-content: center;
    background-color: transparent;
    border: none;
    width: 85%;
    padding: 0;
    margin: 0;
  }
  .socialsCard button {
    width: 25%;
    margin: 0 1%;
    border: 2px solid var(--tert_2);
    border-radius: 16px;
  }
    fieldset {
      border: 2px solid var(--ponc);
      width: 65%;
    }
    legend {
      padding: 0 2.5%;
      border: 2px solid var(--ponc);
    }
    .vert {
      justify-content: center;
      align-items: center;
    }
    .nameDiv {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
    }

    .attrMenu {
      justify-content: center;
      width: 100%;
      margin: 2.5% 0;
    }
    .attrMenu li {
      flex-flow: column wrap;
      align-items: center;
      width: 15%;
    }
    .attrMenu img {
      padding: 5% 0;
    }

    .blogMenu {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 2.5% 0;
    }
    .blogMenu li {
      flex-flow: column wrap;
      width: 55%;
      padding: 1%;
      margin: 1% 0;
      border: 2px solid var(--deux);
      border-radius: 16px;
    }
    legend,
    fieldset {
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .skillMenu {
      margin: 5% 0;
      max-width: 65vh;
    }
    .skillMenu ul {
      flex-flow: row wrap;
      justify-content: center;
      width: clamp(350px, 5vh, 750px);
      margin: 2.5% 0;
      gap: 5%;
    }
    .skillMenu li {
      width: 15%;
    }
    .skillMenu li a {
      flex-flow: column wrap;
      align-items: center;
      width: 100%;
      padding: 1%;
    }
    .skillMenu p {
      color: var(--ponc);
      font-weight: 500;
    }

    @media (max-width: 768px) {
      .blogMenu li {
        padding: 2.5%;
        margin: 2.5%;
        text-align: start;
      }
    }
  `,
];
@customElement({
  name: 'app-landing',
  template,
  styles,
})
export class AppLanding extends FASTElement {}
