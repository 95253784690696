import { FASTElement, html, css, customElement } from '@microsoft/fast-element';
import { bible } from '../../../bible.js';
import { normal } from '../../../normal.js';
import { global } from '../../../global.js';
import '../../components/header-footer.js';

const kAttrMenu = html`
`;

const kPrjxMenu = html`
  <ul class="blogMenu">
  <a class="anchor" href="/musings"><h2>policies</h2></a>
    <li>
      <h2>Terms of Service</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </li>
    <li>
      <h2>Privacy Policy</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </li>
  </ul>
`;
const kSkillMenu = html`
`;
const template = html`
  <header-footer>
   ${kAttrMenu} ${kSkillMenu} ${kPrjxMenu}
    </header-footer>
`;
const styles = [
  bible,
  global,
  normal,
  css`
    fieldset {
      border: 2px solid var(--ponc);
      width: 65%;
    }
    legend {
      padding: 0 2.5%;
      border: 2px solid var(--ponc);
    }
    .vert {
      justify-content: center;
      align-items: center;
    }
    .nameDiv {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
    }

    .attrMenu {
      justify-content: center;
      width: 100%;
      margin: 2.5% 0;
    }
    .attrMenu li {
      flex-flow: column wrap;
      align-items: center;
      width: 15%;
    }
    .attrMenu img {
      padding: 5% 0;
    }

    .blogMenu {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 2.5% 0;
    }
    .blogMenu li {
      flex-flow: column wrap;
      width: 55%;
      padding: 1%;
      margin: 1% 0;
      border: 2px solid var(--deux);
      border-radius: 16px;
    }
    legend,
    fieldset {
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    fieldset {
      margin: 5% 0;
      max-width: 65vh;
    }
    .skillMenu {
      flex-flow: row wrap;
      justify-content: center;
      width: clamp(350px, 5vh, 750px);
      margin: 2.5% 0;
      gap: 5%;
    }
    .skillMenu li {
      width: 15%;
    }
    .skillMenu li a {
      flex-flow: column wrap;
      align-items: center;
      width: 100%;
      padding: 1%;
    }
    .skillMenu p {
      color: var(--ponc);
      font-weight: 500;
    }

    @media (max-width: 768px) {
      .blogMenu li {
        padding: 2.5%;
        margin: 2.5%;
        text-align: start;
      }
    }
  `,
];
@customElement({
  name: 'app-policies',
  template,
  styles,
})
export class AppPolicies extends FASTElement {}
