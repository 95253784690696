
if (!(globalThis as any).URLPattern) {
  await import('urlpattern-polyfill');
}

import { html } from '@microsoft/fast-element';
import { Router } from '@thepassle/app-tools/router.js';

// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';

import './pages/linktree.js';
import './pages/landing.js';
import './pages/portfolio.js';
import './pages/contact.js';
import './pages/musings.js';
import './pages/policies/policies.js';

export const router = new Router({
    routes: [
      {
        path: '/',
        title: 'ihejirika.dev',
        render: () => html`<app-linktree></app-linktree>`
      },
      {
        path: '/amaka',
        title: 'hi',
        render: () => html`<app-landing></app-landing>`
      },
      {
        path: '/contact',
        title: 'contact',
        render: () => html`<app-contact></app-contact>`
      },
      {
        path: '/portfolio',
        title: 'portfolio',
        render: () => html`<app-portfolio></app-portfolio>`
      },
      {
        path: '/musings',
        title: 'amaka\'s musings',
        render: () => html`<app-musings></app-musings>`
      },

      {
        path: '/policies',
        title: 'policies',
        render: () => html`<app-policies></app-policies>`
      },
    ]
  });