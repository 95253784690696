import { css } from "@microsoft/fast-element";

export const bible = css`
/* interactions */
.pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f984;
  backdrop-filter: blur(10px);
}

/* images */
img[class="tiny"] {
  width: 12.5%;
}
img[class="petit"] {
  width: 25%;
}
img[class="med"] {
  width: 50%;
}
img[class="grande"] {
  width: 75%;
}
img[class="gros"] {
  width: 100%;
}

/* stacking */
.vert {
  flex-flow: column wrap;
}
.plein {
  flex-flow: row wrap;
}

/* images responsivity */
.icon {
  min-width:
}
img[id="icon"] {
  width: clamp(25px, 5vh, 50px);
}

.tiny {
  min-width: 12.5%;
}
img[id="tiny"] {
  width: clamp(75px, 10vw, 80px);
}

.petit {
  min-width: 25%;
}
img[id="petit"] {
  width: clamp(125px, 15vh, 175px);
}

.medium {
  min-width: 50%;
}
img[id="med"] {
  width: clamp(200px, 25vh, 325px);
}

.grande {
  min-width: 75%;
}
img[id="grande"] {
  width: clamp(325px, 35vh, 420px);
}

.grosse {
  min-width: 100%;
}
img[id="grosse"] {
  width: clamp(420px, 50vh, 500px);
}

.anchor {
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  color: var(--ponc);
}
;`