import { FASTElement, html, css, customElement } from '@microsoft/fast-element';

import { bible } from '../../bible.js';
import { normal } from '../../normal.js';
import { global } from '../../global.js';
import { categories } from '../data/musings/musings.json';
import '../components/header-footer.js';

const kAttrMenu = html`
  <span class="vert">
    <ul class="attrMenu">
      ${categories
        .map((item) => {
          return `
      <li class="medium">
        <img id="icon" src="${item.img}" />
        <h3>${item.label}</h3>
      </li>
    `;
        })
        .join('')}
    </ul>
    <div class="nameDiv">
      <h1>amaka's musings</h1>
     
    </div>
  </span>
`;

const kPrjxMenu = html`
<span class="blogMenu">
  <ul>
    <li>
      <h2>Blog Post Title</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </li>
    <li>
      <h2>Blog Post Title</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </li>
    <li>
      <h2>Blog Post Title</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </li>
    <li>
      <h2>Blog Post Title</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </li>
    <li>
      <h2>Blog Post Title</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </li>
    <li>
      <h2>Blog Post Title</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </li>
    <li>
      <h2>Blog Post Title</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </li>
    <li>
      <h2>Blog Post Title</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </li>
  </ul>
  </span>
`;
const kSkillMenu = html`
  <fieldset>
    <legend><h3>search</h3></legend>
    <input></input>
  </fieldset>
`;
const template = html`
  <header-footer>
   ${kAttrMenu} ${kSkillMenu} ${kPrjxMenu}
    </header-footer>
`;
const styles = [
  bible,
  global,
  normal,
  css`
    fieldset {
      justify-content: center;
      align-items: center;
      border: 2px solid var(--ponc);
      width: 65%;
    }
    legend {
      padding: 0 2.5%;
      border: 2px solid var(--ponc);
    }
    .vert {
      justify-content: center;
      align-items: center;
    }
    .nameDiv {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
    }

    .attrMenu {
      justify-content: center;
      width: 100%;
      margin: 2.5% 0;
    }
    .attrMenu li {
      flex-flow: column wrap;
      align-items: center;
      width: 15%;
    }
    .attrMenu img {
      padding: 5% 0;
    }


    .blogMenu {
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      gap: 1%;
      max-width: 75%;
      overflow-x: scroll;
      margin: 2.5% 0;
    }
    .blogMenu ul {
      flex-flow: row nowrap;
      justify-content: start;
      align-items: center;
     
      width: 100%;
     
    }
    .blogMenu li {
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      text-align: start;
      margin: 1%;
      width: 225px;
      min-width: 225px;
      height: 175px;
      border: 2px solid var(--deux);
      border-radius: 16px;
    }
    
    .skillMenu {
      flex-flow: row wrap;
      justify-content: center;
      width: clamp(350px, 5vh, 750px);
      margin: 2.5% 0;
      gap: 5%;
    }
    .skillMenu li {
      width: 15%;
    }
    .skillMenu li a {
      flex-flow: column wrap;
      align-items: center;
      width: 100%;
      padding: 1%;
    }
    .skillMenu p {
      color: var(--ponc);
      font-weight: 500;
    }

    @media (max-width: 768px) {
      .blogMenu li {
        padding: 2.5%;
        margin: 2.5%;
        text-align: start;
      }
    }
  `,
];
@customElement({
  name: 'app-musings',
  template,
  styles,
})
export class AppMusings extends FASTElement {}
