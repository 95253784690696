import landing from './landing.json';
import {portfolio} from './portfolio.json';
import {projects} from './linktree.json';
import {header, footer, socials} from './menu.json';
import {categories} from './musings/musings.json';
export {landing, projects, portfolio, header, footer, socials, categories};

export class PortfolioItem {
  public key: string;
  constructor(
    public project: string,
    public logo: string,
    public href: string,
  ) {
    this.key = project.replace(/ /g, '-').replace(/'/g, '').toLowerCase();
  }
}
class PortfolioData extends Array<PortfolioItem> {
  dict: { [key: string]: number } = {};
  constructor() {
    super();
    for (let i = 0; i < portfolio.length; i++) {
      const item = portfolio[i];
      const pi = new PortfolioItem(
        item.title,
        item.logo,
        item.url,
      );
      this.push(pi);
      this.dict[pi.key] = i;
    }
  }
  get(key: string): PortfolioItem {
    return this[this.dict[key]];
  }
  mapDict<T>(fn: (pi: PortfolioItem, i: number) => T): { [key: string]: T } {
    const dict: { [key: string]: T } = {};
    for (let i = 0; i < this.length; i++) {
      const pi = this[i];
      dict[pi.key] = fn(pi, i);
    }
    return dict;
  }
}

export class ProjectItem {
  public key: string;
  constructor(
    public title: string,
    public logo: string,
  ) {
    this.key = title.replace(/ /g, '-').replace(/'/g, '').toLowerCase();
  }
}
class ProjectData extends Array<ProjectItem> {
  dict: { [key: string]: number } = {};
  constructor() {
    super();
    for (let i = 0; i < projects.length; i++) {
      const item = projects[i];
      const pi = new ProjectItem(
        item.title,
        item.logo,
      );
      this.push(pi);
      this.dict[pi.key] = i;
    }
  }
  get(key: string): ProjectItem {
    return this[this.dict[key]];
  }
  mapDict<T>(fn: (pi: ProjectItem, i: number) => T): { [key: string]: T } {
    const dict: { [key: string]: T } = {};
    for (let i = 0; i < this.length; i++) {
      const pi = this[i];
      dict[pi.key] = fn(pi, i);
    }
    return dict;
  }
}

export const kProjectData = new ProjectData();
export const kPortfolioData = new PortfolioData();
export const kLandingItems = landing;

