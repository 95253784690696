import { bible } from '../../bible.js';
import { normal } from '../../normal.js';
import { global } from '../../global.js';
import '../components/header-footer.js';
import { type PortfolioItem, kPortfolioData } from '../data/index.js';
import {
  FASTElement,
  observable,
  html,
  css,
  customElement,
} from '@microsoft/fast-element';
const kInactiveCard: number = -1;
const styles = [
  bible,
  normal,
  global,
  css`
  section {
    flex-flow: column nowrap;
  }


    .portfolioGrid {
      flex-flow: row wrap;
      justify-content: center;
      min-width: 80%;
      gap: 5%;
    }
    .portfolioGrid li {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      min-width: 25%;
      margin: 2.5%;
    }
    .portfolioGrid li a {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      padding: 1%;

      border: 2px solid #eaeaea;
      border-radius: 36px;
    }
    button {
      justify-content: center;
      align-items: center;
      width: 55%;
    }

    dialog {
      background-color: transparent;
      border: none;
      padding: none;

      width: 97.5%;
    }

    @media (max-width: 768px) {
      section {
        flex-flow: column nowrap;
      }

      .prjxGrid {
        flex-flow: row wrap;
        justify-content: center;
    }
  }
  `,
];
const template = html<AppPortfolio>`
  <header-footer>
      <ul class="portfolioGrid">
        ${kPortfolioData
          .map((item: PortfolioItem) => {
            return `
          <li>
            <a target="_blank" href="${item.href}">              
            <img id="med" class="logo" src="${item.logo}" />
            ${item.project}
            </a>
          </li>
        `;
          })
          .join('')}
      </ul>
    </header-footer>
`;
@customElement({
  name: 'app-portfolio',
  template,
  styles,
  shadowOptions: { mode: 'open' },
})
export class AppPortfolio extends FASTElement {
  // @query('app-card')
  gridCard!: HTMLElement;
  // @query('#portfolio-dialog')
  gridDialog!: HTMLDialogElement;
  // @queryAll('.prjx-btn')
  gridButtons!: NodeListOf<HTMLElement>;
  @observable activeCard: number = kInactiveCard;
  @observable dialogIsOpen = false;

  constructor() {
    super();
    this.gridDialog = document.getElementById(
      'portfolio-dialog'
    ) as HTMLDialogElement;
  }


  openDialog() {
    this.dialogIsOpen = true;
    this.gridDialog.showModal();
    this.gridDialog.open = true;

    if (this.activeCard === kInactiveCard) {
      return;
    }
    this.current().render([], this.gridDialog);
  }

  closeDialog() {
    this.dialogIsOpen = false;
    this.gridDialog.close();
  }

  closeDialogClikedOutside(e: Event) {
    if (this.dialogIsOpen && e.target !== this.gridDialog) {
      this.closeDialog();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.gridCard = this.shadowRoot!.getElementById(
      'portfolio-card'
    ) as HTMLElement;
    this.gridButtons = this.shadowRoot!.querySelectorAll('.prjx-btn');
   
    document.addEventListener(
      'click',
      this.closeDialogClikedOutside.bind(this)
    );
    this.gridButtons.forEach((button, index) => {
      button.addEventListener('click', () => this.updateIndex(index));
    });
    
    this.gridDialog.addEventListener('close', () => {
      this.dialogIsOpen = false;
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(
      'click',
      this.closeDialogClikedOutside.bind(this)
    );
    this.gridButtons.forEach((button, index) => {
      button.removeEventListener('click', () => this.updateIndex(index));
    });
  }
  currentItem() {
    // console.log(this.activeCard);
    return kPortfolioData[this.activeCard];
  }

  current() {
    // console.log(this.activeCard);
    let ret = html``;
    if (this.activeCard !== kInactiveCard) {
      ret = html`<app-card .item="${kPortfolioData[this.activeCard]}"></app-card>`;
    }
    this.gridDialog.showModal();
    console.log(ret);
    return ret;
  }

  updateIndex(index: number) {
    this.activeCard = index;
    console.log(this.activeCard);
    this.openDialog();
  }
  // protected createRenderRoot() {
  //   const root = super.createRenderRoot();
  //   root.addEventListener('click',
  //     (e: Event) => this.shadowName = (e.target as Element).localName);
  //   return root;
  // }

  // _openCard() {
  //     this._openCard = e.target === e.currentTarget ?
  //       'container' : (e.target as HTMLDivElement).textContent!;
  //   }
}
