import { header } from '../data/index.js';
import './mobile-menu.js';
import { FASTElement, html, css, customElement } from '@microsoft/fast-element';
import { bible } from '../../bible.js';
import { normal } from '../../normal.js';
import { global } from '../../global.js';
const styles = [
  bible,
  normal,
  global,
  css`
    /* tete */
    header {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      position: sticky;
      top: 0;
      width: 100%;
      margin: 2.5% 0;
      background-color: transparent;
    }

    /* navbar */
    nav {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;

      width: 85%;
      padding: 1% 0;
    }
    ul {
      flex-flow: column wrap;
      justify-content: end;
      align-items: end;
      width: 35%;
      gap: 5%;
    }
    ul a {
      font-size: 18px;
      text-align: end;
      max-width: 10%;
    }

    div {
      justify-content: center;
      align-items: center;
      min-width: 35%;
    }
    .petit {
      justify-content: start;
      align-items: center;
      width: 50%;
    }
    button {
      display: none;
      justify-content: center;
      width: 25%;
      min-width: 25%;
    }
    @media (max-width: 768px) {
      button {
        display: flex;
      }
      ul {
        display: none;
      }
      nav {
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
      }
      header {
        margin: 5% 0;
      }
    }

  `,
];

const template = html`
  <header>
    <nav>
      <a href="/amaka" class="petit">
        <img id="petit" src="/assets/brand-kit/cl_AMAKA.svg" />
      </a>

      <ul>
        ${header
          .map((item) => {
            return `
            <li>
              <a title="nav" href="${item.link}" ${
              item.title === 'CV' ? 'download' : ''
            }> ${item.title} </a>
            </li>
          `;
          })
          .join('')}
    </ul>

    <div>
    <button>
      <img id="icon" src="/assets/icons/menu-rond.svg" />
    </button> 
    </div>

    <mobile-menu></mobile-menu>
    </nav>
  </header>
`;
@customElement({
  name: 'app-header',
  template,
  styles,
})
export class AppHeader extends FASTElement {}
