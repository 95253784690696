import { css } from "@microsoft/fast-element";

export const global = css`
/* flex */
main, section, header, nav, article,
select, option, footer,
pre, code, blockquote, 
div, span, section,
legend, fieldset, 
menu, ol, ul, li,
h1, h2, h3, h4, h5, p, a,
textarea, input,
dialog, form, button,
textarea, input
{
  display: flex;
}

/* viewports */
main {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

/* containers */
legend, fieldset {
    border-radius: 16px;

  }
  legend {
    padding: 0 2.5%;
    margin: 0 2.5%;
    background-color: transparent;
    border: 2px solid var(--ponc);
  }


/* lists */
menu, ol, ul {
  text-align: center;
}

/* text */
h1, h2, h3, h4, h5, h6, p {
  color: var(--trois);

  margin: 0;
  padding: 0;
}
  /* titles */
  h1 {
    color: var(--ponc);
    font-size: clamp(24px, 5vw, 36px);
    font-weight: 400;
  }
  /* sub-titles */
  h2, h3 {
    font-size: clamp(16px, 2.5vw, 20px);
    font-weight: 500;

  }
  h2 {
    font-weight: 600;
  }
  h3 {
    color: var(--ponc);
  }
  h4 {
    color: var(--ponc);
    font-size: clamp(12px, 2.5vw, 16px);
    font-weight: 600;
  }
  h5 {
    font-size: clamp(12px, 2.5vw, 16px);
    font-weight: 500;
  }
  p {
    font-size: clamp(12px, 1vw, 16px);
    font-weight: 400;
  }


a { 
    cursor: pointer;
    color: var(--ponc);
    transition: 0.1s ease-in-out;
  }

  input[type="range"] {
    -webkit-appearance: none;
    border: none;
    outline: none;
    background-color: transparent;
  }
  input[type="text"] {
    border: 1px solid var(--deux);
  }
/* interactions */
  /* buttons */
  button {
  transition: 0.2s ease-in-out;
  border: none;
  background-color: transparent;
 
  }
  /* dialogs */
  /* forms */
  textarea, input {
  border: 2px solid var(--deux);
  border-radius: 8px;
  background-color: transparent;
  color: #000;
}
input[type="range"] {
  background-color: red;
  border: none;
}
textarea {
  height: 10rem;
}
textarea:focus, input:focus {

}
;`