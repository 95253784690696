import { FASTElement, html, css, customElement } from '@microsoft/fast-element';
import { bible } from '../../bible.js';
import { normal } from '../../normal.js';
import { global } from '../../global.js';
const styles = [
  bible,
  normal,
  global,
  css`
  section {
    display: none;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }

    .pop-up div {
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;

      padding: 1% 0;
    }
    ul {
      flex-flow: column wrap;
      justify-content: center;

      width: 50%;
      padding: 10% 0;
      gap: 5%;
    }
    ul li {
      justify-content: center;
      align-items: center;
    }
    ul a {
      justify-content: center;
      align-items: center;
      font-size: 18px;
      text-align: center;
      min-width: 15%;
    }

    button {
      display: none;
      justify-content: center;
      width: 15%;
    }
  

  `,
];
const kMenuItems = [
  {
    title: 'Portfolio',
    link: '/portfolio',
  },
  {
    title: 'CV',
    link: '/assets/brand-kit/resume_AMAKA.pdf',
  },
  {
    title: 'Contact',
    link: '/contact',
  },
];
const template = html`
  <section class="pop-up">
    <div>
      <a href="/amaka" class="petit">
        <img id="petit" src="/assets/brand-kit/cl_MAKS.svg" />
      </a>

      <ul>
        ${kMenuItems
          .map((item) => {
            return `
            <li>
              <a title="nav" href="${item.link}" ${
              item.title === 'CV' ? 'download' : ''
            }> ${item.title} </a>
            </li>
          `;
          })
          .join('')}
    </ul>

    <button>
      <img id="tiny" src="/assets/icons/menu-rond.svg" />
    </button> 

        </div>
        </section>
`;
@customElement({
  name: 'mobile-menu',
  template,
  styles,
})
export class MobileMenu extends FASTElement {}
