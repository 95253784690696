import {
  FASTElement,
  html,
  css,
  customElement,
} from '@microsoft/fast-element';
import { normal } from '../../normal.js';
import { global } from '../../global.js';
import { footer } from '../data/index.js';

const styles = [
  normal,
  global,
  css`
    /* pied */
    footer {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      width: 100%;
      padding: 1% 0;
    }
    /* navbar */
    article {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;

      width: 85%;
    }
    .piedList {
      gap: 2.5%;
      width: 100%;
    }
    .piedList li a {
      font-size: clamp(10px, 1vw, 12px);
    }
  `,
];
const template = html<AppFooter>`
  <footer>
    <article>
   

      <ul class="piedList">
        <li>
          <a href="/"><img id="icon" src="/assets/icons/home-rond.svg" />
          </a>        
        </li>
        ${footer.map((item) => {
          return `
                <li>
                  <a title="nav" href="${item.link}"> ${item.title} </a>
                </li>
              `;
        })
        .join('')}
      </ul>
    </article>
  </footer>
`;
@customElement({
  name: 'app-footer',
  template,
  styles,
})
export class AppFooter extends FASTElement {}
