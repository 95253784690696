import { normal } from '../../normal.js';
import { global } from '../../global.js';
import '../components/header-footer.js';
import { socials } from '../data/index.js';
import { FASTElement, customElement, html, css } from '@microsoft/fast-element';
const styles = [
  normal,
  global,
  css`
  .socialList {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1% 0;
  }


    .socialList li {
      margin: 1%;
      width: 5%;
    }

    form[action='/submit_contact'] {
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    div {
      width: 50%;
    }
    button[class='envoy'] {
      justify-content: center;
      width: 100%;
      padding: 1%;

      border: 2px solid #ff90df;
      border-radius: 8px;
      color: #ff90df;
      font-weight: 500;
    }
    button[class='envoy']:hover {
      background-color: #ff90df40;
      border: 2px solid #ff90df;
      color: #ff90df;
    }
    button[class='envoy']:active {
      background-color: #ff90df;
      border: 2px solid #ff90df;
      color: #fff;
    }
  `,
];
const kFormLabels: Array<string> = ['Name', 'Email', 'Subject'];

const template = html<AppContact>`
  <header-footer>
  <menu class="socialList">
      ${socials
        .map((item) => {
          return `
            <li>
              <a href="${item.url}" target="_blank">
                <img class="icon" src="${item.icon}" />
              </a>
            </li>
          `;
        })
        .join('')}
    </menu>
      <form action="/submit_contact" method="post">
        ${kFormLabels.map((item) => {
          return `
            <div class="form-group">
              <input name="${item}" placeholder="${item}" required />
            </div>
          `;
        }).join('')}
        <div>
          <textarea id="message" name="message" placeholder="Message" required>
          </textarea>
        </div>
        <div>
          <button class="envoy">Send</button>
        </div>
      </form>
      </header-footer>
`;
@customElement({
  name: 'app-contact',
  styles,
  template,
})
export class AppContact extends FASTElement {}
